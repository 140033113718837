import {z} from "zod";
import {deviceCountSchema} from "./IcdDetailsTypes";

export const ICDHistorySchema = z.object({
  _id: z.string().optional(),
  solId: z.string(),
  timestamp: z.number(),
  deviceCount: deviceCountSchema
})

export type DbIcdHistory = z.infer<typeof ICDHistorySchema>;
