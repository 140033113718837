import {z} from "zod"

export const tmzSchema = z.object({
  _id: z.string().optional(),
  customerId: z.string(),
  url: z.string(),
  ttas: z.string(),
  medtronic: z.object({
    sftpServer: z.string(),
    username: z.string(),
    password: z.object({
      iv: z.string(),
      content: z.string()
    }),
  }),
  biotronik: z.object({
    biotronikClientName: z.string(),
    allowDataTransmission: z.boolean()
  }),
  abbott: z.object({}),
  microPort: z.object({}),
  boston: z.object({
    bostonSAPID: z.string(),
  })
})

export type DbTmz = z.infer<typeof tmzSchema>
