import { z } from 'zod';

const addressSchema = z.object({
  street: z.string(),
  postalCode: z.string(),
  location: z.string(),
})

export const dbCustomerSchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
  customerNumber: z.string(),
  doctorName: z.string(),
  biotronikClientName: z.string(),
  biotronikCustomerNumber: z.string(),
  eTin: z.string(),
  biotronikExternalSensorsPrice: z.number(),
  biotronikImplantPrice: z.number(),
  location: z.string(), // TODO property is not necessary and should be removed
  system: z.string(),
  exportCustomer: z.boolean(),
  address: addressSchema,
  bostonSAPID: z.string(),
})

export type customerAddress = z.infer<typeof addressSchema>
export type DbCustomer = z.infer<typeof dbCustomerSchema>
