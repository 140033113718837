import {z} from 'zod';

export const subDeviceSchema = z.object({
  subDeviceNo: z.string(),
  einstellung: z.string(),
  subDeviceSoftwareVn: z.string(),
})

export const clientDeviceSchema = z.object({
  _id: z.string().optional(),
  deviceName: z.string(),
  customerId: z.string(),
  manufacturer: z.string(),
  licenceStart: z.string(),
  licenceEnd: z.string(),
  serialNr: z.string(),
  deviceType: z.string(),
  softwareVn: z.string(),
  subDevice: z.array(subDeviceSchema),
  additionalNotes: z.string(),
  dateOfActivation: z.string(),
  customerName: z.string(),
  customerNumber: z.string(),
  location: z.string(),
  soldToBiotronik: z.boolean()
})


export type SubDevice = z.infer<typeof subDeviceSchema>
export type ClientDevice = z.infer<typeof clientDeviceSchema>
