import React from "react"
import "../App.css"
import PageTitle from "../components/subcomponents/PageTitle";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  LinearProgressProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import TableFactory from "../components/TableFactory";
import {TablePaper} from "../components/TablePaper";
import CustomTabPanel from "../components/CustomTablePanel";
import ErrorExclamation from "../components/subcomponents/ErrorExclamation";
import TableSearch from "../components/subcomponents/TableSearch";
import {Product} from "../models/product";
import AddButton from "../components/subcomponents/Buttons/AddButton";
import DownloadIcon from '@mui/icons-material/Download';
import ButtonWithMargin from "../components/subcomponents/Buttons/ButtonWithMargin";
import {ButtonContainer} from "../components/subcomponents/Containers/Containers";
import {useNavigate} from "react-router-dom";
import ImportExportService from "../services/ImportExportServiceFunctions";
import InCareNetHFService from "../services/InCareNetHFService";
import {activeFieldNames, inActiveFieldNames, Products} from "../constants";
import RefreshIcon from '@mui/icons-material/Refresh';
import {green} from "@mui/material/colors";
import {loadRefreshCountDate} from "../api/software";

interface ProductPageProps {
  productType: string;
}

export const ProductPage: React.FC<ProductPageProps> = ({productType}) => {

  const navigate = useNavigate()

  const [tabValue, setTabValue] = React.useState(0)
  const [loadingProgress, setLoadingProgress] = React.useState(0)
  const [refreshingCounts, setRefreshingCounts] = React.useState(false)
  const [refreshCountDate, setRefreshCountDate] = React.useState('')
  const [items, setItems] = React.useState<any[]>([])
  const [error, setError] = React.useState(false)
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);
  const [selectedDevCountGroup, setSelectedDevCountGroup] = React.useState<string>('active');

  const [searchFilter, setSearchFilter] = React.useState('')

  React.useEffect(() => {
    loadData().then()
  }, [tabValue])

  async function loadData() {
    const selectedProduct = Products[tabValue]
    try {
      setItems(await selectedProduct.getTableItems())
      setError(false)
    } catch (e) {
      console.error(e)
      setError(true)
    }

    const time = await loadRefreshCountDate()
    setRefreshCountDate(time.lastDeviceCountUpdate)
  }

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setTabValue(newValue);
    navigate(`/products/${Products[newValue].name}`)
  }

  function renderTab(label: string, id: string) {
    return (<Tab label={label} id={id} key={id}/>)
  }


  function handleSelectionChange(selectionModel: any) {
    if (selectionModel.length > 1) {
      setSelectedRowIds(selectionModel)
    } else {
      setSelectedRowIds(selectionModel.splice(-1))
    }
  }

  function getSelectedProductObject() {
    return items.find((elem) => (elem._id === selectedRowIds[0]))
  }

  function handleAddClick() {
    navigate("/products/add")
  }

  async function handleUpdate() {
    const selectedProduct = getSelectedProductObject()
    navigate(`/customers/update/${selectedProduct.customerId}?fallbackURL=products/${productType}&product=${selectedProduct._id}`)
  }

  async function handleDelete() {
    if (selectedRowIds.length !== 1) {
      return
    }

    const deleteOperation = Products[tabValue].deleteItem

    await deleteOperation(selectedRowIds[0])

    await loadData()
  }

  function isUpdateButtonDisabled() {
    return selectedRowIds.length !== 1
  }

  function handleDevCountGroupChange(event: SelectChangeEvent) {
    setSelectedDevCountGroup(event.target.value);
  }

  function filterColumnsByDevCountGroup(columnsNames: string[]) {
    return selectedDevCountGroup === 'active' ?
      columnsNames.filter(elem => !inActiveFieldNames.includes(elem))
      : columnsNames.filter(elem => !activeFieldNames.includes(elem))
  }

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Typography
          variant={'h4'}
          textAlign={'center'}
          color="text.secondary">
          {`${Math.round(props.value,)}%`}
        </Typography>
      </Box>
    );
  }


  function createCustomTabPanel(product: Product, index: number) {
    if (error) {
      return <ErrorExclamation message={"Failed to fetch data"}/>
    }

    const filteredItems = items.filter((elem) => {
      return Object.values(elem).some((value: any) =>
        String(value).toString().toLowerCase().includes(searchFilter.toLowerCase())
      );
    });

    let innerElement
    if (productType === 'inCareNetHF') {
      innerElement = renderInCareNetHFTabPanel(product, filteredItems)
    } else {
      innerElement = TableFactory.createTable(
        product.name,
        product.tableColumns.map(elem => elem.field),
        filteredItems,
        selectedRowIds,
        handleSelectionChange,
        {sortModel: [{field: 'customerName', sort: "asc"}]},
      )
    }

    return (
      <CustomTabPanel value={tabValue} index={index} key={product.name}>
        {innerElement}
      </CustomTabPanel>
    )
  }

  function renderInCareNetHFTabPanel(product: Product, filteredItems: any[]) {
    return (
      <div>
        <Grid container spacing={2} direction={"column"} display={'flex'} justifyContent={"space-between"}
              width={'100%'}
              sx={{
                marginTop: 0,
                paddingTop: 0,
                paddingLeft: 2,
                paddingRight: 2,
                flexFlow: 'row wrap'
              }}>
          <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'} marginTop={1}>
            <div style={{display: 'flex', justifyContent: 'left'}}>
              <Box sx={{
                width: "100%",
                textAlign: "left",
                marginBottom: "1rem"
              }}>
                <Select
                  value={selectedDevCountGroup}
                  label={"Gerätezahl-Gruppe"}
                  onChange={handleDevCountGroupChange}
                >
                  <MenuItem key={"active"} value={"active"}>Aktive Gerätezahlen</MenuItem>
                  <MenuItem key={"inactive"} value={"inactive"}>Inaktive Gerätezahlen</MenuItem>
                </Select>
              </Box>
            </div>
          </Grid>

          <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'} marginTop={1}>
            <div style={{display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
              <Button
                variant="contained"
                onClick={async () => {
                  await ImportExportService.downloadCSVFile()
                }}
                style={{marginRight: "2rem"}}
                startIcon={<DownloadIcon/>}
              >CSV</Button>
              <Button
                variant="contained"
                style={{marginRight: "2rem"}}
                onClick={async () => {
                  setRefreshingCounts(true)
                  setLoadingProgress(0)
                  await ImportExportService.createExcel()
                  setLoadingProgress(100)
                  setRefreshingCounts(false)
                  setLoadingProgress(0)
                }}
                startIcon={<DownloadIcon/>}
              >Excel</Button>


              <Box sx={{position: 'relative'}}>
                <Tooltip title={'Last refreshed on: ' + refreshCountDate} arrow>
                  <Fab
                    size={'small'}
                    sx={refreshingCounts ? {
                      animation: "spin .85s ease-in infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "100%": {
                          transform: "rotate(360deg)",
                        },
                      },
                    } : {}}
                    disabled={refreshingCounts}
                    color={'primary'}
                    onClick={async () => {
                      setRefreshingCounts(true)
                      await InCareNetHFService.refreshCounts(setLoadingProgress, setRefreshingCounts)
                      setRefreshingCounts(false)
                    }}
                    variant={'circular'}>
                    <RefreshIcon/>
                  </Fab>
                </Tooltip>

                {
                  refreshingCounts && (
                    <CircularProgress
                      size={'small'}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -2,
                        left: -2,
                        zIndex: 1,
                      }}
                    />
                  )
                }
              </Box>
            </div>
          </Grid>
        </Grid>


        {TableFactory.createTable(
          product.name,
          filterColumnsByDevCountGroup(product.tableColumns.map(elem => elem.field)),
          filteredItems,
          selectedRowIds,
          handleSelectionChange,
          {
            sortModel: [{field: 'customerName', sort: "asc"}],
            summaryFooter: true
          }
        )}
      </div>
    )
  }

  return (
    <div className={"base-container"}>
      <Backdrop sx={{color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1}} open={refreshingCounts}>
        <Box sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}>
          <LinearProgressWithLabel value={loadingProgress}/>
        </Box>
      </Backdrop>


      <PageTitle>Produkte</PageTitle>
      <Divider/>
      <TablePaper>
        <Box>
          <Grid container spacing={2} direction={"column"} display={'flex'} justifyContent={"space-between"}
                width={'100%'}
                sx={{
                  marginTop: 0,
                  paddingTop: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                  flexFlow: 'row wrap'
                }}>
            <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'}>
              <div style={{display: 'flex', justifyContent: 'left'}}>
                <TableSearch onSearch={(input) => {
                  setSearchFilter(input)
                }}/>
              </div>
            </Grid>

            <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'}>
              <div style={{display: 'flex', justifyContent: 'right'}}>
                <AddButton onClick={handleAddClick}/>
              </div>
            </Grid>
          </Grid>


          <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
              {Products.map((product, index) => renderTab(product.label, `tab-${index}`))}
            </Tabs>
          </Box>
          {Products.map((elem, index) => createCustomTabPanel(elem, index))}
          <ButtonContainer>
            <ButtonWithMargin
              variant="contained"
              color="secondary"
              disabled={isUpdateButtonDisabled()}
              onClick={handleDelete}
            >
              Löschen
            </ButtonWithMargin>
            <Button
              variant="contained"
              disabled={isUpdateButtonDisabled()}
              onClick={handleUpdate}
            >
              Bearbeiten
            </Button>
          </ButtonContainer>
        </Box>
      </TablePaper>
    </div>
  )
}
