import {createExcelHeader, createExcelTables, createFooter} from "../excel/ExcelTableCreator";
import {getExcelInfo} from "../excel/BillingService";
import {createSubTables, fillExcelTables} from "../excel/ExcelExportService";
import * as ExcelJS from 'exceljs'
import {timeStamp} from "../utils/helperFunctions";
import CustomerService from "./CustomerService";
import InCareNetHFService from "./InCareNetHFService";
import ConfigService from "./ConfigService";

export default class ImportExportService {

  /**
   * Creates an Excel (XLSX) file which stores data from our customers etc.
   *
   * @returns {Promise<void>}
   */
  static async createExcel(): Promise<void> {
    const customers = await CustomerService.getAllCustomers()
    const sols = await InCareNetHFService.getInCareNetHFs()
    const persistentStateDB = await ConfigService.getConfig();

    const workbook = new ExcelJS.Workbook();
    const minWidth = 10;
    const sheet = workbook.addWorksheet('Kundenreport', {
      pageSetup: {orientation: "landscape"}
    })

    workbook.creator = "SEMDATEX"
    workbook.created = new Date()

    createExcelHeader(sheet)
    createExcelTables(sheet)

    //KEEP 2 "EMPTY" ROWS AND THEN PROCEED
    //THESE 2 ROWS MAY HAVE SOME ADDITIONAL TABLE INFORMATION (E.G. "€/Stück")
    createSubTables(sheet, persistentStateDB)

    //PROCEED HERE:

    //Now actually fill the entries with data
    await fillExcelTables(sheet, await getExcelInfo(sols, customers, persistentStateDB))

    createFooter(sheet, sols.length)

    //Adjusting width, making everything fit correctly
    sheet.eachRow(row => {
      row.eachCell(cell => {
        const width: number = String(cell.value!).length;
        const cellWidth: number = sheet.getColumn(cell.col).width!;

        if (cellWidth === undefined) {
          sheet.getColumn(cell.col).width = width + 5
        }
        if (minWidth + 5 >= cellWidth) {
          sheet.getColumn(cell.col).width = minWidth + 5;
        } else {
          sheet.getColumn(cell.col).width = width + 5
        }
        if (width > cellWidth) {
          sheet.getColumn(cell.col).width = width + 5
        }

        cell.alignment = {...cell.alignment, horizontal: "center", vertical: "middle", wrapText: true}
      })
    })

    //Creating XLSX file for download
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const buffer = await workbook.xlsx.writeBuffer()
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileName = "Kundenreport_" + timeStamp() + ".xlsx";
      const blob = new Blob([buffer], {type: fileType});
      const url = URL.createObjectURL(blob)

      link.setAttribute("href", url)
      link.setAttribute("download", fileName)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  static async downloadCSVFile() {
    const customers = await CustomerService.getAllCustomers()
    const inCareNetHFs = await InCareNetHFService.getInCareNetHFs()
    let csvFormat = "Kundenname;Ort;KundenSystem;KundenNummer;Abbot;Bio;Boston;Medtronic;Microport;ExternalSensors;" + "TotalActiveImplants;AbottInactive;BioInactive;BostonInactive;MedtronicInactive;MicroPortInactive;" + "TotalInactiveImplants;SumTotalImplants\n";
    let length = -1

    inCareNetHFs.forEach((inCareNetHF) => {
      if (inCareNetHF.currentDeviceCount) {

        const totalActive = inCareNetHF.currentDeviceCount.deviceCountActive.Bio
          + inCareNetHF.currentDeviceCount.deviceCountActive.Abbott + inCareNetHF.currentDeviceCount.deviceCountActive.Boston
          + inCareNetHF.currentDeviceCount.deviceCountActive.Medtronic + inCareNetHF.currentDeviceCount.deviceCountActive.MicroPort;

        let totalInactive = 0
        for (const manufacturer of InCareNetHFService.deviceCountManufacturers) {
          // as keyof typeof seems weird, but omits a type edit of InCareNetHF
          const devCountValue = inCareNetHF.currentDeviceCount.deviceCountInactive[manufacturer as keyof typeof inCareNetHF.currentDeviceCount.deviceCountInactive]
          if (devCountValue < 0) {
            continue
          }
          totalInactive += devCountValue
        }

        const totalImplants = (totalActive < 0 || totalInactive < 0) ? -1 : (totalActive + totalInactive)
        for (const customer of customers) {
          if (inCareNetHF.customerId === customer._id) {

            const customerObj = [
              customer.name,
              customer.location,
              customer.system,
              customer.customerNumber,
              inCareNetHF.currentDeviceCount.deviceCountActive.Abbott,
              inCareNetHF.currentDeviceCount.deviceCountActive.Bio,
              inCareNetHF.currentDeviceCount.deviceCountActive.Boston,
              inCareNetHF.currentDeviceCount.deviceCountActive.Medtronic,
              inCareNetHF.currentDeviceCount.deviceCountActive.MicroPort,
              inCareNetHF.currentDeviceCount.deviceCountActive.ExternalSensors,
              totalActive,
              inCareNetHF.currentDeviceCount.deviceCountInactive.Abbott,
              inCareNetHF.currentDeviceCount.deviceCountInactive.Bio,
              inCareNetHF.currentDeviceCount.deviceCountInactive.Boston,
              inCareNetHF.currentDeviceCount.deviceCountInactive.Medtronic,
              inCareNetHF.currentDeviceCount.deviceCountInactive.MicroPort,
              totalInactive,
              totalImplants
            ]

            if (length === -1) {
              length = customerObj.length
            }

            customerObj.forEach(obj => {
              csvFormat += obj + ";"
            })
            csvFormat += "\n"
          }
        }
      }
    })

    csvFormat = this.replaceUmlaut(csvFormat)
    // Replaces failed device count fetch values (-1) with 0.
    csvFormat = csvFormat.replaceAll('-1', '0')

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvFormat);
    hiddenElement.target = '_blank';
    hiddenElement.download = "Kundenreport_" + timeStamp() + ".csv"
    hiddenElement.style.display = "none"
    hiddenElement.hidden = true
    hiddenElement.click()
  }

  static replaceUmlaut(str: string): string {
    return str
      .replace(/\u00df/g, 'ss')
      .replace(/\u00e4/g, 'ae')
      .replace(/\u00f6/g, 'oe')
      .replace(/\u00fc/g, 'ue')
      .replace(/\u00c4/g, 'Ae')
      .replace(/\u00d6/g, 'Oe')
      .replace(/\u00dc/g, 'Ue');
  }
}
