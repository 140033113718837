import React, {useEffect} from 'react'
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography
} from "@mui/material";
import InCareNetHFProductForm, {InCareNetHFFormValues} from "../components/forms/productForms/InCareNetHFProductForm";
import PageFooter from "../components/subcomponents/Footer/PageFooter";
import {styled} from "@mui/material/styles";
import {Product} from "../models/product";
import {FormIds, Products} from "../constants";
import InCareNetHFService from "../services/InCareNetHFService";
import {useNavigate} from "react-router-dom";
import InPaceProductForm, {InPaceProductFormValues} from "../components/forms/productForms/InPaceProductForm";
import InPaceService from "../services/InPaceService";
import CustomerService from "../services/CustomerService";
import {DbCustomer} from "sharedlibs";

const FooterPlaceholder = styled("span")({
  height: "3rem",
  display: "block"
})

interface FormPageProps {
  title: string,
}

export const AddProductPage: React.FC<FormPageProps> = ({title}) => {

  const navigate = useNavigate()

  const [selectedProduct, setSelectedProduct] = React.useState<string>('');
  const [customers, setCustomers] = React.useState<DbCustomer[]>([]);
  const [productFormDirty, setProductFormDirty] = React.useState<boolean>(false);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState("")
  const [submitLoading, setSubmitLoading] = React.useState(false)

  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState("")
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success")

  const changeCustomer = (event: SelectChangeEvent) => {
    setSelectedCustomerId(event.target.value)
  };

  const changeProduct = (event: SelectChangeEvent) => {
    setSelectedProduct(event.target.value);
  };

  async function handleSubmit(formObject: InCareNetHFFormValues | InPaceProductFormValues) {
    setSubmitLoading(true)

    const customer = customers.find((customer) => customer._id === selectedCustomerId)
    if (customer === undefined) {
      throw new Error("Selected customer could not be found!")
    }

    switch (selectedProduct) {
      case "inCareNetHF": {
        try {
          await InCareNetHFService.addInCareNetHF(customer, formObject as InCareNetHFFormValues)

          setShowSnackbar(true)
          setSnackbarSeverity("success")
          setSnackbarMessage("Produkt erfolgreich gespeichert")

          navigate("/products/inCareNetHF")
        } catch (e: any) {
          setShowSnackbar(true)
          setSnackbarSeverity("error")
          setSnackbarMessage("Fehler beim Speichern des Produkts, Fehler: " + e.message)
          alert(productFormDirty)
        }
        break
      }
      case "inPace": {
        try {
          if (!customer) {
            setShowSnackbar(true)
            setSnackbarSeverity("error")
            setSnackbarMessage("Fehler beim Speichern des Produkts," +
              " Fehler: Es wurde kein passender Kunde gefunden um den notwendigen Kundenname, Kundennummer und Location auszulesen")
            return
          }

          await InPaceService.addDevice(customer, formObject as InPaceProductFormValues)

          setShowSnackbar(true)
          setSnackbarSeverity("success")
          setSnackbarMessage("Produkt erfolgreich gespeichert")

          navigate("/products/inPace")
        } catch (e: any) {
          setShowSnackbar(true)
          setSnackbarSeverity("error")
          setSnackbarMessage("Fehler beim Speichern des Produkts, Fehler: " + e.message)
        }
        break
      }
      default: // TODO
        setSubmitLoading(false)
        return
    }

  }

  function getDefaultFormValues(): InCareNetHFFormValues | InPaceProductFormValues | null {
    switch (selectedProduct) {
      case "inCareNetHF":
        return {
          url: "",
          token: "",
          tmzActiveSince: null,
          priceImplantsPerPatient: "",
          priceSensorsPerPatient: "",
          os: "",
          getemedHotlineSupport: false,
        }
      case "inPace":
        return {
          deviceName: "",
          serialNr: "",
          deviceType: "",
          manufacturer: "Semdatex",
          softwareVn: "",
          licenceStart: null,
          licenceEnd: null,
          dateOfActivation: null,
          additionalNotes: "",
          subDevice: [],
          soldToBiotronik: false
        }
      default:
        return null
    }

  }

  useEffect(() => {
    CustomerService.getAllCustomers().then((customerList) => {
      setCustomers(customerList)
    }).catch((e) => {
      console.error(e)
    })
  }, []);

  function renderProductForm() {
    if (!selectedCustomerId) {
      return (<div>Wähle einen Kunden und ein Produkt</div>)
    }

    switch (selectedProduct) {
      case "inCareNetHF":
        return (<InCareNetHFProductForm onSubmit={handleSubmit}
                                        defaultValues={getDefaultFormValues() as InCareNetHFFormValues}
                                        updateDirty={setProductFormDirty}/>)
      case "inPace":
        return (<InPaceProductForm onSubmit={handleSubmit}
                                   defaultValues={getDefaultFormValues() as InPaceProductFormValues}
                                   updateDirty={setProductFormDirty}/>)
      default:
        return (<div>TODO</div>)
    }
  }

  function getProductFormId() {
    switch (selectedProduct) {
      case "inCareNetHF":
        return FormIds.updateInCareNetHF
      case "inPace":
        return FormIds.updateInPace
      default:
        return ""
    }
  }

  return (
    <div>
      <Typography
        variant={"h3"} sx={{ml: 3, marginTop: 5, marginBottom: 2}}>
        {title}
      </Typography>
      <Divider/>

      {/*Customer Selection*/}
      <Paper sx={{mx: 3, my: 3}} elevation={2}>
        <div style={{padding: 15, marginBottom: '15px', display: 'flex', flexDirection: 'column'}}>
          <FormControl id={'form_control_customer'} variant={'standard'} sx={{margin: 1}}>
            <InputLabel id={'select_customer_label'}>Kunde</InputLabel>
            <Select
              sx={{width: 300}}
              labelId={'select_customer_label'}
              label="Kunde"
              id={'customer_selector'}
              value={selectedCustomerId}
              onChange={changeCustomer}
            >
              {customers.map((customer: any) => {
                return <MenuItem key={customer._id}
                                 value={customer._id}>{customer.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          <FormControl id={'form_control_product'} variant={'standard'} sx={{margin: 1}}>
            <InputLabel id={'select_product_label'}>Produkt</InputLabel>
            <Select
              sx={{width: 300}}
              labelId={'select_product_label'}
              id={'product_selector'}
              value={selectedProduct}
              onChange={changeProduct}
            >
              {Products.map((product: Product) => {
                return <MenuItem key={product.name}
                                 value={product.name}>{product.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
      </Paper>

      {/* Product section */}
      <Paper sx={{mx: 3, padding: 1}} elevation={2}>
        <Box sx={{padding: "1rem"}}>
          <div>
            <Typography sx={{marginBottom: 1, paddingTop: 0}} variant={'h4'}>Produktdaten</Typography>
            <Divider/>
          </div>
          {renderProductForm()}
        </Box>
      </Paper>
      <FooterPlaceholder/>

      {/* PageFooter */}
      <PageFooter>
        <Button variant="contained" color="inherit">
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={getProductFormId()}
          disabled={submitLoading || !productFormDirty}
        >
          Speichern
        </Button>
      </PageFooter>

      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity as "success" | "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
