import {z} from "zod";

export const statisticsSchema = z.object({
  date: z.date(),
  inCareNetHFUseCount: z.number(),
  vericiguatPatientCount: z.number(),
  totalPatientCount: z.number(),
  patientsPrescribedByDoctorCount: z.number(),
  patientsWith2_5mgDoseCount: z.number(),
  patientsWith5mgDoseCount: z.number(),
  patientsWith10mgDoseCount: z.number(),
})

export const regionSchema = z.object({
  percentage: z.number(),
  statistics: statisticsSchema
})


export const bdcSchema = z.object({
  _id: z.string().optional(),
  creationDate: z.date(),
  regionOne: regionSchema,
  regionTwo: regionSchema,
  regionThree: regionSchema,
  regionFour: regionSchema,
})

export const bayerUsageStatisticSchema = z.object({
  dosage2point5: z.object({
    tmz: z.number(),
    pba: z.number(),
  }),
  dosage5: z.object({
    tmz: z.number(),
    pba: z.number(),
  }),
  dosage10: z.object({
    tmz: z.number(),
    pba: z.number(),
  })
})

export type BayerUsageStatisticObject = z.infer<typeof bayerUsageStatisticSchema>;
export type BDCObject = z.infer<typeof bdcSchema>
export type RegionObject = z.infer<typeof regionSchema>
export type StatisticsObject = z.infer<typeof statisticsSchema>
