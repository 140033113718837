import {z} from "zod";

export const activeDeviceCountItem = z.object({
  patId: z.string(),
  categories: z.array(z.string()),
})

export const activeDeviceCountSchema = z.object({
  Bio: z.array(activeDeviceCountItem),
  Abbott: z.array(activeDeviceCountItem),
  Boston: z.array(activeDeviceCountItem),
  Medtronic: z.array(activeDeviceCountItem),
  MicroPort: z.array(activeDeviceCountItem),
  ExternalSensors: z.array(activeDeviceCountItem),
})

export const inactiveDeviceCountSchema = z.object({
  Bio: z.number(),
  Abbott: z.number(),
  Boston: z.number(),
  Medtronic: z.number(),
  MicroPort: z.number(),
})

export const deviceCountSchema = z.object({
  deviceCountActive: activeDeviceCountSchema,
  deviceCountInactive: inactiveDeviceCountSchema
})

export const IcdDetailSchema = z.object({
  _id: z.string().optional(),
  solId: z.string(),
  getemedHotlineSupport: z.boolean(),
  priceSensorsPerPatient: z.number(),
  priceImplantsPerPatient: z.number(),
  currentDeviceCount: deviceCountSchema,
  tmzActiveSince: z.string(),
  discount: z.number(),
  isDeviceInactiveFailed: z.boolean(),
});

export type DeviceCounts = z.infer<typeof deviceCountSchema>;
export type DbIcdDetail = z.infer<typeof IcdDetailSchema>;
