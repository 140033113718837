import {io} from "socket.io-client";
import {serverDomain} from "../constants";
import KeycloakFunctions from "../keycloakFunctions";

export function initateSocketIOConnection() {
  const token = KeycloakFunctions.getToken()

  return io(serverDomain, {
    extraHeaders: {
      'Authorization': `Bearer ${token}`
    }
  })
}
