import {BillingConfigFormValues} from "../components/forms/BillingConfigForm";
import {Config} from "sharedlibs";
import {getConfig, updateConfig} from "../api/config";

export default class ConfigService {

  static async getConfig(): Promise<Config> {
    return await getConfig()
  }

  static async updateConfig(formObject: BillingConfigFormValues) {
    const config = this.mapBillingFormToConfig(formObject)

    if (!ConfigService.checkShareRevenues(config, "implants")) {
      alert('Die Summe der Implantatumsatzbeteiligungen muss 100% ergeben')
      return
    }

    if (!ConfigService.checkShareRevenues(config, "sensors")) {
      alert('Die Summe der Umsatzbeteiligungen der externen Sensoren muss 100% ergeben')
      return
    }

    await updateConfig(config)
  }

  static mapConfigToBillingConfigForm(config: Config): BillingConfigFormValues {
    return {
      semdatexFixedCosts: config.semdatexFixedCosts.toString(),
      getemedFixedCosts: config.getemedFixedCosts.toString(),
      getemedHotlinePrice: config.getemedHotlinePrice.toString(),
      semdatexImplantRevenueShare: config.implantRevenueShare.semdatex.toString(),
      biotronikImplantRevenueShare: config.implantRevenueShare.biotronik.toString(),
      DocCirrusImplantRevenueShare: config.implantRevenueShare.docCirrus.toString(),
      semdatexExternalSensorsRevenueShare: config.revenueShare.semdatex.toString(),
      getemedExternalSensorsRevenueShare: config.revenueShare.getemed.toString(),
      biotronikExternalSensorsRevenueShare: config.revenueShare.biotronik.toString(),
      DocCirrusExternalSensorsRevenueShare: config.revenueShare.docCirrus.toString(),
    }
  }

  private static mapBillingFormToConfig(billingFormData: BillingConfigFormValues): Config {
    return {
      semdatexFixedCosts: parseFloat(billingFormData.semdatexFixedCosts),
      getemedFixedCosts: parseFloat(billingFormData.getemedFixedCosts),
      getemedHotlinePrice: parseFloat(billingFormData.getemedHotlinePrice),
      implantRevenueShare: {
        semdatex: parseFloat(billingFormData.semdatexImplantRevenueShare),
        biotronik: parseFloat(billingFormData.biotronikImplantRevenueShare),
        docCirrus: parseFloat(billingFormData.DocCirrusImplantRevenueShare)
      },
      revenueShare: {
        semdatex: parseFloat(billingFormData.semdatexExternalSensorsRevenueShare),
        getemed: parseFloat(billingFormData.getemedExternalSensorsRevenueShare),
        biotronik: parseFloat(billingFormData.biotronikExternalSensorsRevenueShare),
        docCirrus: parseFloat(billingFormData.DocCirrusExternalSensorsRevenueShare)
      }
    }
  }

  /**
   * Checks if the revenue shares of either implants or sensors are valid. The sum must be 100 (percent).
   * @param config Config object which should be checked
   * @param subject Either "implants" or "sensors" depending on which revenue shares should be checked
   */
  static checkShareRevenues(config: Config, subject: "implants" | "sensors") {
    return subject === "implants" ?
      config.implantRevenueShare.biotronik + config.implantRevenueShare.docCirrus + config.implantRevenueShare.semdatex === 100 :
      config.revenueShare.semdatex + config.revenueShare.getemed + config.revenueShare.biotronik + config.revenueShare.docCirrus === 100
  }
}
