import React, {useEffect} from 'react'
import {Controller, useForm} from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Dayjs} from "dayjs";
import 'dayjs/locale/en-gb';
import InputAdornment from "@mui/material/InputAdornment";
import {firstLevelSupport, FormIds} from "../../../constants";
import InCareNetHFService from "../../../services/InCareNetHFService";

export interface InCareNetHFFormValues {
  url: string
  token: string
  tmzActiveSince: Dayjs | null
  priceImplantsPerPatient: string
  priceSensorsPerPatient: string
  os: string
  getemedHotlineSupport: boolean
}

export interface InCareNetHFProductFormProps {
  onSubmit: (data: InCareNetHFFormValues) => void
  /** This function updates the dirty state in the parents component */
  updateDirty: (dirty: boolean) => void,
  defaultValues: InCareNetHFFormValues
}

export default function InCareNetHFProductForm({
                                                 onSubmit,
                                                 defaultValues,
                                                 updateDirty
                                               }: Readonly<InCareNetHFProductFormProps>) {
  const form = useForm<InCareNetHFFormValues>({
    defaultValues
  })

  const {register, control, handleSubmit, formState, getValues, setValue, watch} = form

  const watchedOs = watch('os', getValues().os) // Triggers rerender on changed values in select
  const {errors} = formState

  useEffect(() => {
    updateDirty(form.formState.isDirty)
  }, [form.formState.isDirty])

  return (
    <form id={FormIds.updateInCareNetHF} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2} direction={"column"} display={'flex'} justifyContent={"space-between"} width={'100%'}
            sx={{
              marginTop: 2,
              paddingTop: 2,
              paddingLeft: 2,
              paddingRight: 2,
              flexFlow: 'row wrap'
            }}>
        {/* Left form element*/}
        <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'45%'}>
          <Grid container display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Stack spacing={3}>
              <TextField
                id={"url"}
                variant="standard"
                label="URL"
                required
                {...register("url", {
                  required: "URL wird benötigt",
                  validate: InCareNetHFService.validateICNUrl,
                })}
                error={!!errors.url}
                helperText={errors.url?.message}
              />
              <TextField
                id={"token"}
                variant="standard"
                label="Token"
                required
                {...register("token", {
                  required: "Token wird benötigt"
                })}
                error={!!errors.token}
                helperText={errors.token?.message}
              />
              <Stack direction={"row"} spacing={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    label={"TMZ aktiv seit"}
                    value={getValues().tmzActiveSince}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!errors.tmzActiveSince,
                        helperText: errors.tmzActiveSince?.message,
                      },
                    }}
                    {...register("tmzActiveSince", {
                      required: "TMZ aktiv seit wird benötigt",
                    })}
                    onChange={value => setValue('tmzActiveSince', value, { shouldDirty: true })}
                  />
                </LocalizationProvider>
                <TextField
                  id={"priceImplantsPerPatient"}
                  variant="outlined"
                  label="Implantat Preis"
                  type="number"
                  required
                  InputProps={{endAdornment: <InputAdornment position={'end'}>€</InputAdornment>}}
                  {...register("priceImplantsPerPatient", {
                    required: "Implantatspreis wird benötigt"
                  })}
                  error={!!errors.priceImplantsPerPatient}
                  helperText={errors.priceImplantsPerPatient?.message}
                />
                <TextField
                  id={"priceSensorsPerPatient"}
                  variant="outlined"
                  label="Sensor Preis"
                  required
                  type="number"
                  InputProps={{endAdornment: <InputAdornment position={'end'}>€</InputAdornment>}}
                  {...register("priceSensorsPerPatient", {
                    required: "Sensorpreis wird benötigt"
                  })}
                  error={!!errors.priceSensorsPerPatient}
                  helperText={errors.priceSensorsPerPatient?.message}
                />
              </Stack>
              <FormControl id={'form_control_os'} variant={'standard'}>
                <InputLabel id={'form_os_label'}>OS</InputLabel>
                <Select
                  id={"form_os_selector"}
                  sx={{width: '300px'}}
                  labelId={'form_os_label'}
                  value={watchedOs}
                  onChange={event => setValue('os', event.target.value as string, {shouldDirty: true})}
                >
                  <MenuItem value={"el7"}>EL7</MenuItem>
                  <MenuItem value={"el8"}>EL8</MenuItem>
                  <MenuItem value={"el9"}>EL9</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        {/*//TODO add form changing*/}
        {/* Right form element*/}
        <Grid container width={'45%'} display={'flex'} flexDirection={'column'}>
          <Typography variant={'h6'}>Feature Toggles</Typography>
          <FormGroup sx={{display: 'flex', flexDirection: 'row'}}>
            <FormControlLabel control={<Switch disabled/>} label={"inSuite Billing"}/>
            <FormControlLabel control={<Switch disabled/>} label={"Bayer Project"}/>
            <FormControlLabel control={<Switch/>} label={"AI ECG Assesment"}/>
          </FormGroup>

          <Typography variant={'h6'}>Support</Typography>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <FormControl id={'form_active_support'} variant={'standard'} sx={{mr: 5}}>
              <InputLabel id={'form_active_support_label'}>First Level Support</InputLabel>
              <Select
                sx={{width: 300}}
                labelId={'form_active_support_label'}
                id={'form_active_support_selector'}
                // value={selectedProduct}
                // onChange={changeProduct}
              >
                {firstLevelSupport.map((support) => {
                  return <MenuItem value={support}>{support}</MenuItem>
                })}
              </Select>
            </FormControl>

            <Controller
              name={'getemedHotlineSupport'}
              control={control}

              render={({field}) => <FormControlLabel control={<Checkbox checked={Boolean(field.value)} {...field} />}
                                                     label={'mit GETEMED Telefon Support'}/>}
            />

          </div>
        </Grid>
      </Grid>
    </form>
  )
}
